import React from "react";
import HTMLFlipBook from "react-pageflip";


const PageCover = React.forwardRef((props, ref) => {
  return (
    <div
      className={"page page-cover-" + props.pos}
      ref={ref}
      data-density="hard"
    >
      <img className="page-image" src={props.imageUrl}/>
      <div className="page-content">
        <h2>{props.text}</h2>
      </div>
    </div>
  );
});

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref} data-density={props.density | "soft"}>
      {props.imageUrl ? 
      <img className="page-image" src={props.imageUrl}/> :
      <img className="border-image" src="leafyFrame.png"/>}
      <div className={"page-content " + (props.imageUrl ? "page-content-image" : "page-content-no-image")}>
        <h2>{props.text}</h2>
      </div>        
      <div className={"page-footer " + (props.number%2 == 1 ? "page-footer-left" : "page-footer-right")}>{"Page " + props.number}</div>
    </div>
  );
});

 export const Storybook = ({ storyData }) => {
  var serverPageList = storyData.data.getStory.pages.items.sort((a,b) => a.pageNumber - b.pageNumber);


  const pages = [ ];

  for (let i = 0; i <= serverPageList.length; i++) {
    if (i === serverPageList.length) {
      // end 
      pages.push(<PageCover key={1234567889} text={"the end"} number={i} pos="bottom"/>);
      break;
    }
    let page = serverPageList[i];
    if (i === 0) {
      // cover
      pages.push(<PageCover key={i} pos="top" imageUrl={page.imageUrl} text={page.text} number={i}/>);
    } else {
      pages.push(<Page key={i} imageUrl={page.imageUrl} text={page.text} number={i}/>);
    }
  }

  return (
    <div>
      <HTMLFlipBook
        width={450}
        height={650}
        size="stretch"
        minWidth={315}
        maxWidth={450}
        minHeight={400}
        maxHeight={650}
        maxShadowOpacity={0.5}
        showCover={true}
        className="flip-book html-book"
      >
        {pages}
      </HTMLFlipBook>
    </div>
  );

}
