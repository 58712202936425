


export const DownloadLink = () => {
  return (
    <a href="https://apps.apple.com/is/app/id6448074104">
      <div
        className={"download-link"} > 
        <img className="download-link-image" src="logo128.png"/>
        <div className="download-link-content">StoryTimeAI: Create your own stories!</div>
      </div>
    </a>
  )
}