import './App.css';
import { Storybook } from './StoryBook';
import { DownloadLink } from './DownloadLink';
import { Async } from 'react-async';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDRphjRhZlYfm_9gPi2en1twwMxjueNoQg",
  authDomain: "storytime-ai-df27f.firebaseapp.com",
  projectId: "storytime-ai-df27f",
  storageBucket: "storytime-ai-df27f.appspot.com",
  messagingSenderId: "795185352098",
  appId: "1:795185352098:web:80b50fa86b3b3f0bbdf6fb",
  measurementId: "G-YGK9HD9Z7G"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const loadStory = async () => {
  const searchParams = new URLSearchParams(document.location.search)
  var storyId = "1B3AB451-3CDB-471E-ADBD-E4A21A3D9252"
  if (searchParams && searchParams.get("storyId")) {
    storyId = searchParams.get("storyId");
  }
  
  return await fetch("https://9jhh1zwolh.execute-api.us-east-1.amazonaws.com/prod/book/" + storyId)
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json())
}

function App() {
  return <Async promiseFn={loadStory}>
      <Async.Pending>
        <div>
          <p className='page-content'>Loading...</p>
          <br></br>
          <DownloadLink/>
        </div>
      </Async.Pending>
      <Async.Fulfilled>{data => <div><Storybook storyData={data}/><br></br><DownloadLink/></div>}</Async.Fulfilled>
      <Async.Rejected>{error => <p className='page-content'>{error.message}</p>}</Async.Rejected>
    </Async>
}

export default App;
